import React from "react";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import RecentBlogPosts from "../components/Repeating/RecentBlogPosts";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";
import ScrollNavigation from "../components/Scroll/ScrollNavigation";
import ButtonUnderline from "../components/Button/ButtonUnderline";

const Page = ({ data }) => {
  const url = typeof window !== "undefined" ? window.location.pathname : "";
  return (
    <Layout>
      <SearchEngineOptimization
        title="Mortgage Basics | Right Start | Mortgage Lender"
        description="Learn key home loan terms in our Mortgage Basics Guide—because the more you know, the better you'll be prepared. Contact us if you have questions!"
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="mb-20 md:mb-28 md:pt-12">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <StaticImage
                src="../images/6.0 Mortgage Basics/1.0 mortgage basics-hero.png"
                loading="eager"
                width={560}
              />
            </div>
            <div>
              <h1>Mortgage Basics</h1>
              <p>
                If you’re considering applying for a home loan, there are some
                basic terms that are important to review before you begin. The
                more you know, the better you’ll be prepared.
              </p>
              <ButtonSolid
                modal="modal-contact"
                text="Contact Us"
                altStyle={2}
              />
            </div>
          </div>
        </div>
      </section>

      <section className="mb-20 bg-primary-50 py-20 md:mb-32 md:py-32">
        <div className="container">
          <header className="mb-12 md:mb-24">
            <h2>The Basics</h2>
          </header>

          <div className="grid md:grid-cols-12 md:gap-x-14 lg:gap-x-18">
            <ScrollNavigation className="hidden md:col-span-4 md:col-start-1 md:block">
              <li>
                <AnchorLink
                  to={url + "#section-1"}
                  title="Application Checklist"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink to={url + "#section-2"} title="Credit" stripHash />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-3"}
                  title="Closing Costs"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-4"}
                  title="Appraisals"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink to={url + "#section-5"} title="PMI" stripHash />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-6"}
                  title="Refinancing"
                  stripHash
                />
              </li>
              <li>
                <AnchorLink
                  to={url + "#section-7"}
                  title="Foreclosures"
                  stripHash
                />
              </li>
            </ScrollNavigation>

            <div className="space-y-12 md:col-span-8 md:col-end-13 md:space-y-24">
              <div
                id="section-1"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Application Checklist</h3>
                <p>
                  When you’re ready to apply, here are the items you may be
                  asked to provide with your application:
                </p>
                <ul className="styled-list-checkmark">
                  <li>Photo identification</li>
                  <li>Pay stubs for your most recent 30-day period</li>
                  <li>W-2 forms for the past 2 years</li>
                  <li>
                    Names and addresses of your employers for the last 2 years
                  </li>
                  <li>If self-employed, 2 years of full tax returns</li>
                  <li>Proof of any other income you have, such as rent</li>
                  <li>Bank statements for the last 3 months</li>
                  <li>Credit card and loan statements</li>
                  <li>A list of any other debt you have, including alimony</li>
                </ul>
              </div>

              <div
                id="section-2"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Credit</h3>
                <p className="mb-0">
                  Your credit report is one of the most important factors that
                  lenders review. It indicates whether you have a history of
                  missing payments or paying late, maxed out your credit cards,
                  or opened many new accounts quickly. In other words, lenders
                  are looking for red flags that indicate you’re an unreliable
                  borrower.
                </p>
              </div>

              <div
                id="section-3"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Closing Costs</h3>
                <p>
                  Closing costs encompass many fees but simply put, they are the
                  processing fees you pay to close your loan. They typically
                  include the following:
                </p>
                <ul className="styled-list-checkmark">
                  <li>Origination costs</li>
                  <li>Application fees</li>
                  <li>Credit check and monitoring</li>
                  <li>Rate lock fees</li>
                  <li>Title costs</li>
                  <li>Home appraisal</li>
                  <li>Property taxes</li>
                  <li>Homeowners insurance</li>
                  <li>Private mortgage insurance</li>
                  <li>HOA fees (if required)</li>
                  <li>Inspection fees</li>
                  <li>Real estate agent fees</li>
                  <li>Attorney fees</li>
                  <li>Mortgage points</li>
                </ul>
              </div>

              <div
                id="section-4"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Appraisals</h3>
                <p className="mb-0">
                  An appraisal, which is an estimate of the property’s fair
                  market value, is required for many loan programs. This helps
                  ensure the value of the home you are buying is in line with
                  your mortgage loan amount. A licensed Appraiser will evaluate
                  the property’s location, amenities, and physical condition and
                  consider the value of other homes in the area.
                </p>
              </div>

              <div
                id="section-5"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">
                  Private Mortgage Insurance (PMI)
                </h3>
                <p className="mb-0">
                  For a conventional mortgage with a down payment of less than
                  20%, a lender will require that you have private mortgage
                  insurance (PMI). The insurance protects them in the event you
                  default on your loan. The cost will vary, and borrowers can
                  either pay a 1-year premium at closing or have the PMI added
                  to their monthly mortgage payment.
                </p>
              </div>

              <div
                id="section-6"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Refinancing</h3>
                <p>
                  When you refinance, you’re replacing your existing home loan
                  with a new one. Typically you’ll get a better interest rate, a
                  lower monthly payment, and have the option to cash out equity
                  at the same time. You can also choose to reduce the loan’s
                  term, such as switching from a 30-year loan to a 15-year one.
                </p>
                <ButtonUnderline href="/home-refinance/" text="Learn More" />
              </div>

              <div
                id="section-7"
                className="rounded-3xl bg-white px-6 py-12 shadow-4xl md:p-14"
              >
                <h3 className="heading-four">Foreclosures</h3>
                <p className="mb-0">
                  If a homeowner cannot pay their monthly mortgage and stops
                  making payments, the lender has the right to seize and sell
                  the property. The legal process for the seizure is called
                  foreclosure and is typically triggered when a borrower misses
                  a certain number of payments. Foreclosures can also be
                  triggered if a borrower fails to meet other terms.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <RecentBlogPosts />
      <CallToAction />
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "Open Graph/Facebook/Mortgage Basics.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "Open Graph/Twitter/Mortgage Basics.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
